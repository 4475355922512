

.main {
    position: relative;
    width: 100%;
    padding: 60px 20px 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff6e4;
    backdrop-filter: blur(0);
    border-bottom: 1px solid black;
}

.header {
    font-size: 21px;
    font-weight: 400;

    font-family: "Times New Roman", serif;
    border-bottom: 1px solid black;
    color: black;
    background: #fff6e4;
}

.menu {
    font-family: 'Montserrat Variable', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    font-size: 24px;
    transition: .3s all ease;
}
@media (min-width: 780px) {
    .menu {
        font-size: 20px;

    }
}
@media (max-width: 780px) {


    .menu {
        position: absolute;
        font-family: 'Montserrat Variable', sans-serif;
        top: 0;
        left: 0;
        z-index: 200;
        width: 100vw;
        background-color: rgba(255, 246, 228, 0.9);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
        font-size: 24px;
        transition: .3s all ease;
    }

    .menu_hidden {
        visibility: hidden;
        opacity: 0;
        z-index: -100;
    }

    .menu_visible {
        opacity: 1;
        z-index: 200;
        overflow: hidden;
    }
}

h2 {
    font-weight: 400;
}



.title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 64px !important;
    z-index: 100;
    padding: 0 20px 10px;
    color: white;
    text-align: center;
    backdrop-filter: blur(5px);
    background: rgb(255,242,215, 0.1);

    border-radius: 10px;
    font-weight: 400 !important;
    font-family: Italiana, sans-serif !important;
    border: 1px solid rgb(255,242,215, 0.5);

    animation: titleAnimation 2s  ease;
}

@keyframes titleAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.description {
    position: absolute;
    top: 64%;
    width: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    z-index: 100;
    color: white;
    text-align: center;
    font-weight: 500;
    border-radius: 10px;
    animation: titleAnimation 2s  ease;


}

.video-container {
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
    position: relative;
}

.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content {
    width: 100vw;
    background-color: #fff6e4;
}

.section {
    padding: 40px 20px 0;
    border-bottom: 1px solid black;
}



@media (min-width: 780px) {
    .main {
        padding: 60px 40px 40px;

    }
    .section {
        padding: 80px 40px 0;
        display: flex;
        justify-content: space-between;
    }
    .section_content {
        width: 30%;
    }

}

.section_title {
    font-family: Italiana, sans-serif;
    font-size: 28px;
    margin-bottom: 20px;
}

@media (min-width: 1200px) {
    .section_title {
        font-size: 48px;
        margin-bottom: 40px;
    }
    .section_content {
        font-size: 18px;
        line-height: 28px;
    }
    .section {
        border: none;
    }
}



.section > div {
    margin-bottom: 20px;
}

.section > span {
    margin-bottom: 20px;
}

span {
    display: inline-block;
}

.footer {
    font-size: 14px;
    background-color: #fff2d5;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.input {

    border-radius: 20px;
    border: 1px solid black;
    padding: 8px 20px;
}

input {
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0.5);
    outline: none;
    box-sizing: border-box;
}

input:focus {
    border: 1px solid #695200;


    background-color: rgba(255, 255, 255, 1);
}
.gallery_photo {
    transition: .3s all ease;

}
.gallery_photo:hover {
    transition: .3s all ease;

    transform: scale(1.02);
}

.rooms_gallery_arrow {
    transition: .3s all ease;
    background-color: rgba(253,237,205,0.3);

}

.rooms_gallery_arrow:hover {
    transition: .3s all ease;
    background-color: rgba(253,237,205,1);
}




body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, html {
  font-family: 'Montserrat Variable', sans-serif;
  font-weight: 500;
}



@font-face {
  font-family: 'Italiana';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/italiana@latest/latin-400-normal.woff2) format('woff2'), url(https://cdn.jsdelivr.net/fontsource/fonts/italiana@latest/latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* montserrat-latin-wght-normal */
@font-face {
  font-family: 'Montserrat Variable';
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/montserrat:vf@latest/latin-wght-normal.woff2) format('woff2-variations');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

.button {
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid black;
  padding: 8px 20px;
  background: white;
  text-align: center;
  border-radius: 100px;
  color: black;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Montserrat Variable', sans-serif;
  text-decoration: unset;
}

.button:hover {
  transition: all 0.3s ease;
  background: transparent;
  border: 1px solid white;
  color: white;
  transform: scale(1.05);
}

.button-booking {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  bottom: 20px;
  width: calc(100% - 40px);

  cursor: pointer;
  transition: all 0.3s ease;
  padding: 8px 20px;
  border: 1px solid #23387E;
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  color: #23387E;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Montserrat Variable', sans-serif;
  text-decoration: unset;
}

.button-booking:hover {
  transition: all 0.3s ease;

  transform: scale(1.03);
}

.button-maps {
  padding: 0;
  color: #34A853;
  background-color: white;
  border: 1px solid transparent;
}

.button-insta {
  display: flex;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid black;
  padding: 8px 20px;
  background: white;
  border-radius: 100px;
  color: black;
  font-size: 18px;
  align-items: center;
  text-decoration: unset;
  justify-content: center;
  gap: 10px;
}

@media (max-width: 780px) {
  .button-insta {
    font-size: 16px;
    padding: 8px 0 !important;

  }
}

.button-insta:hover {
  transition: all 0.3s ease;
  transform: scale(1.05);
}
.button-gallery {
  transform: translate(-50%, 0%)
}

.button-gallery:hover {
  transition: all 0.3s ease;
  transform: translate(-50%, 0%) scale(1.05);
}


.button-booking-visible {
  opacity: 1;
}

.button-booking-hidden {
  opacity: 0;
}

.swiper {
  width: 100%;
  padding-bottom: 30px !important;
  cursor: grab;
  overscroll-behavior: none;

  -webkit-user-select: none; /* Chrome, Safari, Opera */
  -moz-user-select: none;    /* Firefox */
  -ms-user-select: none;     /* Internet Explorer/Edge */
  user-select: none;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 200px;
  height: 200px;
}



.swiper-slide img {
  display: block;
  height: 200px;
  border-radius: 5px;
  width: 100%;
  object-fit: cover;
}

@media (min-width: 780px) {
  .swiper-wrapper {
    padding-top: 20px;
  }
  .swiper-slide img {
    display: block;
    height: 300px;
    border-radius: 5px;
    width: 100%;
    object-fit: cover;
  }
  .swiper {
    width: 100%;
    padding-top: 80px;
    padding-bottom: 50px;
  }

  .swiper-slide {
    background-size: cover;
    height: 500px;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
  }

  .swiper-slide video {
    display: block;
    width: 100%;
  }
  .slide_gallery {
    width: 500px !important;
  }
}



.swiper-pagination {
  border-radius: 0px 0px 5px 5px;
}
.swiper-pagination-bullet {
  width: 7px !important;
  height: 7px !important;

}
.swiper-pagination-bullet-active {
  background-color: rgba(0, 0, 0, 0.8) !important;
}


.css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root {
  padding: 0 !important;
}

.input {
  border-radius: 20px;
}



.swiper-pagination {
  bottom: 3px !important;
}

.no-scroll {
  overflow: hidden;
}
@media (min-width: 1200px) {
  .instagram-media {
    width: 600px !important;
  }
}

h1 {
  margin: 0;
}
h2 {
  margin-top: 0 !important;
}